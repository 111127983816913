import { borders, shadows } from "../../styles/variables";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import React from "react";
import styled from "@emotion/styled";

const Styled = styled.section`
  .contents {
    max-width: var(--smallWrapperWidth);
    margin: 0 auto;
    padding: var(--gutter-medium);
    border: ${borders.light1};
    box-shadow: ${shadows.light};
    background: var(--white);
    font-size: var(--font-size-x-small);
  }

  h2 {
    text-align: center;
  }

  .heading--underlined {
    &::after {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .img {
    display: block;
    max-width: 100%;
    border-radius: 50%;
    height: 300px;
    width: 300px;
    margin: 0 auto var(--gutter-normal);
  }
`;

const About = ({ image }) => (
  <Styled id="about">
    <div className="row">
      <h2 className="heading--underlined">About Me</h2>
      <div className="contents">
        <div className="img-container">
          <StaticImage src="../../images/vn.jpg" alt="Vladyslav Nyzhnyk" class="img" />
        </div>
        <div className="text">
          <p>
            My name is Vladyslav Nyzhnyk, and I am a highly skilled full stack developer
            with expertise in AI/ML, web scraping and automation. Residing in
            the bustling neighborhood of Lviv, Ukraine, and a proud graduate of{" "}
            <a href="https://lpnu.ua/">LPNU</a>, I bring a unique blend of
            technical prowess and creative problem-solving to every project I
            undertake. With a passion and a team-oriented mindset, I approach
            challenges with dedication and strive to elevate projects to new
            heights. Let's collaborate and achieve outstanding results together!
          </p>

          <p>
            Apart from sitting in front of Computer 🖥️ I like playing basketball
            🏀 and love spending time outdoors in Nature 🌴
          </p>
          <p>
            Got something to say or need any kind of help please feel free to{" "}
            <Link to="/contact/">reach out</Link> I'll try my best to respond.
          </p>
        </div>
      </div>
    </div>
  </Styled>
);

export default About;
