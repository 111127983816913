import React from "react";
import { sizes } from "../../styles/variables";
import styled from "@emotion/styled";
const StyledTrustedBy = styled.section`
  text-align: center;

  .heading--underlined {
    &::after {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: var(--gutter-medium);

    & > img {
      margin: 0 5rem;
      margin-bottom: var(--gutter-medium-1);
    }
  }

  img {
    height: 4.5rem;
    max-width: 18rem;
    -webkit-filter: grayscale(1) opacity(0.6);
    filter: grayscale(1) opacity(0.6);

    @media (max-width: ${sizes.breakpoints.lg}) {
      height: 4rem;
      max-width: 17rem;
    }
  }

  .theia {
    opacity: 0.8;
  }

  .open-vsx {
    max-width: 24rem;
  }

  .ideacraft {
    transform: scale(0.95);
  }

  .agency-elevation {
    transform: scale(0.9);
    opacity: 0.8;
  }

  .logrocket {
    transform: scale(0.95);
  }

  .materialize {
    opacity: 0.85;
  }
`;

const SkillSet = () => (
  <StyledTrustedBy id="trusted-by">
    <div className="row">
      <h2 className="heading--underlined">My SkillSet</h2>
      <div className="logos-container">
        {/* <div className="logos">
          {companies.map((c, i) => (
            <img src={c.img} alt={c.alt} className={c.className} />
          ))}
        </div> */}

        <p>
          I am a Full Stack Engineer specializing in AI/ML with expertise in web
          automation and scraping.
          <br />
          My skillset includes proficiency in
          <br />
          <a href="https://www.w3schools.com/html/html_css.asp" target="_blank">
            HTML/CSS
          </a>
          , &nbsp;
          <a href="https://www.python.org/" target="_blank">
            Python
          </a>
          , &nbsp;
          <a href="https://www.javascript.com/" target="_blank">
            JavaScript
          </a>
          , &nbsp;
          <a href="https://www.typescriptlang.org/" target="_blank">
            TypeScript
          </a>
          , &nbsp;
          <a href="https://nodejs.org" target="_blank">
            Node.js
          </a>
          , &nbsp;
          <a href="https://react.dev/" target="_blank">
            React
          </a>
          ,&nbsp;
          <a href="https://www.gatsbyjs.com/" target="_blank">
            Gatsby
          </a>
          ,&nbsp;
          <a href="https://nextjs.org/" target="_blank">
            Next
          </a>
          ,&nbsp;
          <a href="https://expressjs.com/" target="_blank">
            Express
          </a>
          ,&nbsp;
          <a href="https://www.djangoproject.com/" target="_blank">
            Django
          </a>{" "}
          for Web Development,
          <br /> AI/ML frameworks like{" "}
          <a href="https://www.tensorflow.org/" target="_blank">
            Tensorflow
          </a>
          ,&nbsp;{" "}
          <a href="https://scikit-learn.org/" target="_blank">
            PyTorch
          </a>
          ,&nbsp;{" "}
          <a href="https://keras.io/" target="_blank">
            Keras
          </a>
          ,&nbsp;{" "}
          <a href="https://caffe.berkeleyvision.org/" target="_blank">
            Caffe
          </a>
          ,&nbsp;{" "}
          <a href="https://scikit-learn.org/" target="_blank">
            Scikit-learn
          </a>
          ,&nbsp;
          {/* <a href="https://pypi.org/project/Theano/" target="_blank">
            Theano
          </a>
          ,&nbsp;{" "}
          <a href="https://deeplearning4j.konduit.ai/" target="_blank">
            DL4J
          </a>
          ,&nbsp; <br />
          NLP, CV libraries like&nbsp;
          <a href="https://opencv.org/" target="_blank">
            OpenCV
          </a>
          ,&nbsp;{" "}
          <a href="https://openface.io" target="_blank">
            OpenFace
          </a> */}
          {/* ,&nbsp;{" "} */}
          <a href="https://www.nltk.org/" target="_blank">
            NLTK
          </a>
          ,&nbsp;{" "}
          <a href="https://www.langchain.com/" target="_blank">
            LangChain
          </a>
          ,&nbsp;
          <a href="https://chat.openai.com/" target="_blank">
            ChatGPT
          </a>
          ,&nbsp;
          {/* and for data processing &nbsp; */}
          {/* <a href="https://numpy.org/" target="_blank">
            Numpy
          </a>
          ,&nbsp;
          <a href="https://scipy.org/" target="_blank">
            Scipy
          </a> 
          ,&nbsp;*/}
          <br /> and web automation / scraping tools like &nbsp;
          <a href="https://www.selenium.dev/" target="_blank">
            Selenium
          </a>
          ,&nbsp;
          <a href="https://pptr.dev/" target="_blank">
            Puppeteer
          </a>
          ,&nbsp;
          <a href="https://pypi.org/project/beautifulsoup4/" target="_blank">
            BeautifulSoup
          </a>
          ,&nbsp;
          <a href="https://scrapy.org/" target="_blank">
            Scrapy
          </a>
          ,&nbsp;
          <br />
          as well as database management and analysis for informed
          decision-making.
        </p>
      </div>
    </div>
  </StyledTrustedBy>
);

export default SkillSet;
