import AgencyElevation from "../images/agency-elevation.jpg";
import Project from "./project";
import React from "react";
import TypeFoxPreview from "../images/typefox-io.png";
import CompanyBioGenerator from "../images/CompanyBioGenerator.png";
import Questify from "../images/Questify.png";
import VisonaryPlates from "../images/VisonaryPlates.png";

const projects = [
  {
    path: CompanyBioGenerator,
    description: "Company Bio Generator",
    title: "texta.ai",
    url: "https://app.texta.ai/short-tools/company-bio",
    tools: ["React", "Typescript", "FastAPI", "OpenAI", "Pinecone"],
  },
  {
    path: Questify,
    description: "First P2E Game platform based on SEI blockchain",
    title: "Questify",
    url: "https://questify-frontend-violetgen.vercel.app/",
    tools: ["React", "Typescript", "TailwindCSS", "Express", "MongoDB"],
  },

  {
    path: TypeFoxPreview,
    description:
      "Website for TypeFox a company that builds dev tools & programming languages for engineers.",
    title: "typefox.io",
    url: "https://typefox.io",
    tools: ["React", "TypeScript", "TailwindCSS"],
  },
  {
    path: VisonaryPlates,
    description: "Automatic License Plate Recognition",
    title: "Visonary Plates",
    url: "https://github.com/flurryunicorn/Licence_Plate_Detection",
    tools: ["PyTorch", "Ultralytcs", "Cuda", "YOLOv5 & v8 xl"],
  },
  {
    path: AgencyElevation,
    description: "Website for Agency Elevation.",
    title: "agencyelevation.com",
    url: "https://www.agencyelevation.com/",
    tools: ["Typescript", "React", "Gatsby", "Emotion"],
  },
];

const Work = () => (
  <section id="work">
    <div className="row">
      <h2 className="heading--underlined">My Work</h2>
      <div>
        {projects.map((p, i) => (
          <Project key={i} {...p} />
        ))}
      </div>
    </div>
  </section>
);

export default Work;
