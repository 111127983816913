import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Testimonial from "./testimonial";
import styled from "@emotion/styled";

const StyledTestimonials = styled.section`
  text-align: center;

  .heading--underlined {
    &::after {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .testimonials {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
    }

    & > * {
      flex: 0 0 49%;
    }
  }
`;

const Testimonials = () => (
  <StyledTestimonials id="testimonials">
    <div className="row">
      <h2 className="heading--underlined">Testimonials</h2>
      <div className="testimonials">
        <Testimonial
          name="Andrew Nakamura"
          role="Full Stack Engineer"
          quote="I had the pleasure of working alongside my remarkable colleague, who is an exceptionally impressive full stack engineer. With a deep understanding of AI/ML and outstanding problem-solving abilities, he consistently delivered innovative solutions that surpassed expectations. His expertise, combined with an unwavering dedication to excellence, made him an invaluable asset to our team."
        >
          <StaticImage
            src="../../images/andrewN.jpg"
            alt="Nicholas Shook"
            style={{ borderRadius: "50%" }}
            imgStyle={{ borderRadius: "50%" }}
          />
        </Testimonial>
        <Testimonial
          name="Thomas Meta"
          role="CEO of Solarity Questify"
          quote="I am incredibly grateful for the exceptional expertise and dedication that our full stack engineer has demonstrated in attracting over 10,000 users within just one week for our project. His technical prowess, strategic thinking, and unwavering determination have proven instrumental in our success, and I cannot wait to recommend his skills and contributions highly enough."
        >
          <StaticImage
            src="../../images/thomasM.jpg"
            alt="Thomas Meta"
            style={{ borderRadius: "50%" }}
            imgStyle={{ borderRadius: "50%" }}
          />
        </Testimonial>
      </div>
    </div>
  </StyledTestimonials>
);

export default Testimonials;
